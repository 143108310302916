// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.saveButton {
  background-color: #ED5C2F !important;
  color: white !important;
  margin-top: 40px !important; 
  font-size: 1rem !important;
}

.redirectOLC{
  font-size: 15px !important;
  margin-left: 162px !important; 
}

.fillAllFields{
  margin-left: 20px !important; 
}


.formDiv{
  margin-top: 30px !important; 
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;;AAGA;EACE,2BAA2B;AAC7B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.saveButton {\n  background-color: #ED5C2F !important;\n  color: white !important;\n  margin-top: 40px !important; \n  font-size: 1rem !important;\n}\n\n.redirectOLC{\n  font-size: 15px !important;\n  margin-left: 162px !important; \n}\n\n.fillAllFields{\n  margin-left: 20px !important; \n}\n\n\n.formDiv{\n  margin-top: 30px !important; \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
