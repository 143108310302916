// App.js
import './index.css'
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardHeader, CardContent, TextField, Button, MenuItem } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    width: '100%', 
    maxWidth: 900, 
    height: '400px',
    margin: 'auto',
    marginTop: 20,
    backgroundColor: '#ED5C2F',
  },
  header: {
    backgroundColor: '#ED5C2F', 
    color: '#fff',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    width: '75%', 
    marginBottom: 10,
  },
  modeInput: {
    width: '20%',
    marginBottom: 10,
  }
});

// const modes = ['live', 'test'];
const modes = [
  { key: "Live", value: 'live' },
  { key: "Test", value: 'test' }
];

function App() {
  
  const classes = useStyles();
  const [olcKey, setOlcKey] = useState('');
  const [authenticationStatus, setAuthenticationStatus] = useState(null); 
  const [selectedMode, setSelectedMode] = useState(modes[0].key); // State to track the selected mode
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const portalId = queryParams.get('u');
  const installationUrl = `https://app.hubspot.com/integrations-settings/${portalId}/installed`;
  
  const fetchData = async () => {
    try {
      if (!olcKey) {
        setAuthenticationStatus('required');
        return;
      }

      const apiUrl = `${process.env.REACT_APP_OLC_BACKEND_URL}/auth/verify-token`;
      const response = await axios.post(apiUrl, { token: olcKey }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response?.data?.data?.success) {
        const saveTokenUrl = `${process.env.REACT_APP_HUBSPOT_BACKEND_URL}/token/save`;
        const saveTokenResponse = await axios.post(saveTokenUrl, {
          portalId: portalId,
          token: olcKey,
          mode: selectedMode.toLowerCase(),
          status: "active"
        });
       
        if (saveTokenResponse?.data?.success) {
          setAuthenticationStatus("success");
          window.location.href = installationUrl;
        } else {
          console.log("error", saveTokenResponse);
          // setAuthenticationStatus('token_not_saved');
        }

      } else {
        setAuthenticationStatus('not_authenticated');
      }
    } catch (error) {
      console.log('Error fetching data.', error);
    }
  };

  return (
    <div>
       {authenticationStatus === 'success' && (
        <div style={{ color: 'green', textAlign: 'center', margin: '10px 0' }}>
          Authentication successful! Redirecting...
        </div>
      )}

      {authenticationStatus === 'required' && (
        <div style={{ color: 'red', textAlign: 'center', margin: '10px 0' }}>
          API key is required.
        </div>
      )}

      {(authenticationStatus === 'not_authenticated' || authenticationStatus === 'token_not_saved') && (
        <div style={{ color: 'red', textAlign: 'center', margin: '10px 0' }}>
          Not authenticated. Please enter a valid API key again.
        </div>
      )}

    
      <Card className={classes.card}>
        <CardHeader className={classes.header} title="Open Letter Connect API Configuration" />
        <CardContent>
          <div className='fillAllFields'>
            <p>Please fill all fields</p>
          </div>
          <div className='formDiv'>
          <form className={classes.form} >
            <div className={classes.inputContainer}>
               
            <TextField
              className={classes.modeInput}
              select
              label="Select Mode"
              value={selectedMode}
              variant="outlined"
              onChange={(e) => setSelectedMode(e.target.value)}
            >
              {modes.map((mode) => (
                <MenuItem key={mode.key} value={mode.key}>
                  {mode.key}
                </MenuItem>
              ))}
            </TextField>
              <TextField
                className={classes.input}
                label="Open Letter Connect Key"
                variant="outlined"
                placeholder="live_sk_ra7GVfdkGppmfE4as8wkdJ"
                value={olcKey}
                onChange={(e) => setOlcKey(e.target.value)}
                />
              </div>
              <div className="redirectOLC" >
                <p >
                  <a href={`${process.env.REACT_APP_OLC_FRONTEND_URL}`} target="_blank">(If you don't have an API key, sign up for Open Letter Connect here to receive one!)</a>
                </p>
              </div>
              
              <Button
                className="saveButton"
                onClick={fetchData}
              >
                Save
              </Button>
          </form>
          </div>
         
        </CardContent>
      </Card>
      {/* <Toaster /> */}
    </div>
  );
}

export default App;
